.goodPin {
    fill: var(--myGreen)
}

.badPin {
    fill: var(--myRed)
}

.generalPin {
    fill: var(--myDarkBlue)
}