#addPinPage {
    display: flex;
}

#addPinPage>.Sidebar {
    position: fixed;
    left: 0px;
}

#addPinPage .addPinForm {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 0 1em;
    background-color: white;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
}

@media screen and (max-width: 480px) {
    #addPinPage .addPinForm {
        width: 100%;
        margin: 10px;
    }
}

@media screen and (min-width: 481px) {
    #addPinPage .addPinForm {
        width: 80%;
        margin: 5em 10% 5em 20%;
    }
}

#addPinPage .addPinForm div.actionButtons {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
}   

.actionButtons {
    margin-top: 2em;
    text-align: right;
    flex-direction: row !important;
    justify-content: flex-end;
}

@media screen and (max-width: 450px) {
    .actionButtons {
        display: block !important;
        flex-direction: column !important;
    }
}

#addPinPage .addPinForm form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

#addPinPage .addPinForm input[type=text], input:disabled {
    margin-left: 1em 0em;
    margin-right: 1em 0em;
    margin-bottom: 1em 0em;
    padding-left: 1em;
    border-radius: 5px;
    border-width: 1px;
    border-color: lightgray;
    height: 3em;
    vertical-align: top;
}

#addPinPage .addPinForm textarea {
    resize: vertical;
    max-height: 6em;
    padding-top: 1em;
    padding-left: 1em;
    margin-left: 1em 0em;
    margin-right: 1em 0em;
    margin-bottom: 1em 0em;
    border-radius: 5px;
}

#addPinPage .addPinForm label {
    cursor: pointer;
}

#addPinPage .addPinForm .feedbackButtonGroup {
    display: flex;
    flex-direction: row;
}

#addPinPage .addPinForm .map-container>.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
}

#addPinPage .addPinForm .map-container {
    width: 100%;
    height: 60vh;
    display: block;
}

#addPinPage .addPinForm #descriptionInput {
    height: 5em;
}

#addPinPage .addPinForm .headline2 {
    font-size: 14pt;
}

#addPinPage .addPinForm .locationInput {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}