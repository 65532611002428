.Sidebar {
  position: fixed;
  min-height: 100vh;
  width: 10%;
  margin: 0;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.TitleText {
  color: white;
  text-align: center;
}

.SidebarTop {
  background-color: var(--myBlue);
  padding-bottom: 10px;
  padding-top: 20px;
  margin-bottom: 10px;
}

.SidebarLogo {
  width: 50%;
  background-color: white;
  display: block;
  margin: auto;
  clip-path: circle(45% at 47.5% 50%);
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  margin: 0;
}

.SidebarList .row {
  width: 100%;
  height: 5.750em;
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: LatoLight, serif;
  margin-bottom: 10px;
}
  
.SidebarList .bottom {
  position: fixed;
  bottom: 0;
  width: 10%;
  height: 5.750em;
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: LatoLight, serif;
  margin-bottom: 10px;  
}

.SidebarList .row:hover {
  cursor: pointer;
  fill: white;
  color: white;
  background-color: var(--myBlue);
}

.SidebarList #active {
  color: white;
  background-color: var(--myBlue);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

div .sidebarMenuItem  {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SidebarImage:not(:hover) {
  color: var(--myBlue);
  margin: 0.625em;
}

.SidebarList .row:hover .SidebarImage {
  fill: white;
  color: white;
  margin: 0.625em;
}

.SidebarList #active .SidebarImage {
  fill: white;
  color: white;
  margin: 0.625em;
}


@media screen and (max-width: 480px) {
  .Sidebar {
    visibility: hidden;
    display: none;
  }
}