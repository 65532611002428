.button-container-add-button {
    background-color: var(--myBlue);
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 2;
    cursor: pointer;
}

/* On screens that are 992px or less - make the add button appear closer to the edge of the screen */
@media screen and (max-width: 992px) {
    .button-container-add-button {
        bottom: 25px;
        right: 25px;
    }
  }

.icon-add-button {
    margin: auto;
}