#projectResultsPage .pinDialog {
    z-index: 2;
    top: 2%;
    right: 2%;
    position: fixed;
}

#projectResultsPage .resultsDialog {
    background-color: white;
    border-radius: 5px;
    padding: 1em;
    width: 20em;
    z-index: 2;
    bottom: 3%;
    right: 2%;
    position: fixed;
}