.leaflet-container {
  z-index: 1;
  width: 90%;
  height: 100vh;
  position: fixed;
  left: 10%;
  display: flex;
}

.map-container{
  display: flex;
  height: 100vh;
}

.map {
  flex-grow: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .leaflet-container {
    width: 100%;
    left: 0;
  }
}