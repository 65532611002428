#loginPage {
    display: flex;
}

#loginPage > .loginForm  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: fit-content;
    width: 25%;
    /* margin: 0 1em; */
    margin-left: 10em;
    margin-top: 10em;
}

#loginPage .input-container {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    margin: 1em 1em 0 1em;
}

#loginPage .subheader {
    margin-left: 0.75em;
}

#loginPage .button-container {
    display: flex;
    justify-content: center;
}

#loginPage input[type="email"], input[type="password"] {
    padding: 0 0 0 1em;
    height: 4em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-family: LatoRegular, serif;
    font-size: 1em;
}

#loginPage input[type="submit"] {
    margin: 1em 1em 0 1em;
    cursor: pointer;
    background: var(--myBlue);
    color: #fff;
    padding: 10px 20px;
    width: 100%;
    border-radius: 4px;
    height: 3em;
    font-family: LatoRegular, serif;
    font-size: 1em;
    border: none;
    margin-bottom: 1em;
}

#loginPage input[type="submit"]:hover {
    background: var(--mySea);
}

@media screen and (min-width: 821px)  { 
    #loginPage > .loginForm {
        width: 25%;
        margin-left: 42.5%;
        margin-right: 32.5%;
        margin-top: 15%;
        margin-bottom: 20%;
    }
}

@media screen and (min-width: 481px) and (max-width: 820px) { 
    #loginPage > .loginForm {
        width: 50%;
        margin-left: 30%;
        margin-right: 20%;
        margin-top: 40%;
    }
}

@media screen and (max-width: 480px)  { 
    
    #loginPage > .loginForm {
        width: 479px;
        margin: 0 1em;
        margin-top: 40%;
    }
}	