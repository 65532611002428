#projectPage {
    display: flex;
}

#projectPage .actionMenu {
    display: flex;
    justify-content: flex-end;
}

#projectPage .actionMenu .deleteIcon {
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    #projectPage .projects {
        width: 100%;
    }
}

@media screen and (min-width: 481px) {
    #projectPage .projects {
        width: 90%;
        margin-left: 10%;
    }
}

#projectPage .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#projectPage .SideBar {
    position: fixed;
    left: 0px;
}

#projectPage .projectItem {
    margin: 1.5em 3em;
    background-color: white;
    width: 40%;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
    padding: 1em;
    max-width: 530px;
}

/* On screens that are 992px or less - make project card almost full width */
@media screen and (max-width: 1400px) {
    #projectPage .projectItem {
        margin: 1.5em 1em;
        width: 80%;
    }
}

#projectPage .projectItem .map-container {
    height: 13em;
    top: 50%;
    left: 50%;
    background-color: #00363d;
    margin-bottom: 1em;
}

#projectPage .projectItem .map-container>.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
}

#projectPage .menu {
    text-align: justify;
}

#projectPage .projectContainer {
    display: flex;
}