#menuWraper {
    visibility: hidden;
}

div .phoneMenu {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 5%;
    padding-bottom: 5%;
    background: var(--myBlue);
}

#phoneMenuBody>.phoneMenuTitle {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center; 
    align-self: center;
    font-size: 20pt;
    color: white;
    font-family: LatoLight, serif;
}

#phoneMenuBody>.hamburger-react {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
}

.menuItem {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menuItemIcon {
    margin: 7.5%;
    /* margin-left: 1em; */

}

a.menuItemText {
    font-size: 1.5em;
    font-weight: 600;
    color: white;
    font-family: LatoLight, serif;
}

div .menu-in {
    z-index: 3;
    position: absolute;
    animation: slide-in 0.5s both;
    width: 100%;
    height: fit-content;
    background: var(--myBlue);
}

div .menu-out {
    z-index: 3;
    position: absolute;
    animation: slide-out 0.5s both;
    width: 100%;
    height: fit-content;
    background: var(--myBlue);
}

div .my-menu-hidden {
    display: none;
}

@keyframes slide-in {
    0% {
        transform: translateX(-125%);
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        visibility: hidden;
        transform: translateX(-125%);
        opacity: 0;
    }
}

@media screen and (max-width: 480px) {
    #menuWraper {
        visibility: visible;
    }
}

@media screen and (min-width: 481px) {
    #menuWraper {
        display: none;
    }
}