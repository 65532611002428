.feedbackPopup {
    z-index: 5;
    display: flex;
    height: fit-content;
    width: 35em;
    position: fixed;
    padding: 0 1em;
    background-color: #ffffff;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
    left: 30%;
    top: 2.5em;
}

.close-btn {
    z-index: 3;
    position: absolute;
    background: none;
    border: none;
    top: 0px;
    right: 0px;   
}

.close-btn:hover {
    cursor: pointer;
}

.popup-inner h1 {
    position: relative;
    font-size: 1.5em;
    color: var(--myDarkBlue);
    font-family: LatoRegular, serif;
}

.popup-inner p {
    position: relative;
    font-size: 1em;
    color: var(--myDarkBlue);
    top: -0.5em;
    text-align: justify;
    font-family: LatoRegular, serif;
}

@media screen and (max-width: 480px) {
    .feedbackPopup {
        width: 80%;
        left: 0%;
        top: 20%;
        padding: 0 5%;
        margin-left: 5%;
        margin-right: 5%;
    }
}