#addPinPage .addPinForm label.feedbackButton {
    opacity: 0.3;
    border-radius: 20px;
    width: 7em;
    height: 1.5em;
    line-height: 150%;
    display: inline-block;
    text-align: center;
    align-content: center;
    color: white;
}

#addPinPage .addPinForm label[for=dislikeButton] {
    background-color: var(--myRed);
}

#addPinPage .addPinForm label[for=likeButton] {
    background-color: var(--myGreen);
}

#addPinPage .addPinForm input[type=radio]:checked+label {
    opacity: 1;
}

#addPinPage .addPinForm input[type=radio] {
    opacity: 0;
}
