@font-face {
    font-family: 'LatoRegular';
    src: local('Lato'), url(./assets/fonts/Lato/Lato-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'LatoLight';
    src: local('Lato'), url(./assets/fonts/Lato/Lato-Light.ttf) format('opentype');
}

:root {
    --myBlue: #004174;
    --mySea: #007888;
    --myDarkBlue: #00233D;
    --myDarkSea: #00363D;
    --myGreen: #258003;
    --myRed: #C92318;
    --myBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 -2px 4px rgba(0, 0, 0, 0.10);
}

.headline1, .headline2, .subheader, .buttonText, .body1, .body2, .textField {
    font-family: LatoRegular, serif;
}

.textField {
    font-size: 12pt;
}

p {
    text-align: justify;
}

.menu, .title {
    font-family: LatoLight, serif;
}

.subheader, .menu, .body1, .body2, .headline1 {
    color: var(--myDarkBlue);
}

.title, .buttonText, .headline1 {
    color: var(--myBlue);
}

.headline2 {
    color: var(--mySea);
}

.headline1 {
    font-size: 20pt;
}

.headline2 {
    font-size: 12pt;
}

.title {
    font-size: 8pt;
}

.subheader {
    font-size: 16pt;
}

body.map-background {
    background-image: url('./assets/img/map-background.png');
    background-position: center center;
    background-size: cover;
    /* Make image center */
    background-position: center center;
    /* Make image fixed */
    background-attachment: fixed; 
    background-repeat: repeat;
    background-size: auto;
    background-size: 140%;
}

/* Media query for mobile devices  */
@media only screen and (max-width: 2100px) {
    body.map-background {
        background-image: url('./assets/img/map-background-phone.png');
    }
}

.menu, .body2 {
    font-size: 1em;
}

.buttonText, .body1 {
    font-size: 1em;
}

a:visited, a:active, a:link {
    color: var(--mySea);
    text-decoration: none;
}

a:hover {
    color: var(--myBlue);
    text-decoration: none;
}

.Toastify__toast {
    font-family: LatoLight, serif;
}

.hidden-element {
    display: none;
}