#greetingDialog {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    height: fit-content;
    width: 20em;
    padding: 0 1em;
    background-color: #ffffff;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
}

#greetingDialog #accessCodeInput {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#greetingDialog h1 {
    font-size: 1.5em;
    color: var(--myDarkBlue);
}

#greetingDialog p {
    font-size: 1em;
    color: var(--myDarkBlue);
    top: -0.5em;
    text-align: justify;
}

#greetingDialog input {
    padding: 0 0 0 1em;
    height: 2.9em;
    width: 8.5em;
    border: 0.1em solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-family: LatoRegular, serif;
    font-size: 1em;
}

#greetingDialog hr {
    margin: auto 2em;
}

#greetingDialog button {
    color: #ffffff;
    background: var(--myBlue);
    cursor: pointer;
    border: none;
    font-family: LatoRegular, serif;
    font-size: 1em;
    height: 3em;
    border-radius: 4px;
    padding: 10px 20px;
}

#greetingDialog .close-btn {
    background: none;
    z-index: 5;
    font-size: 2em;
    padding: 0;
    margin: 5px;
    height: 1em;
    width: 1em;
    top: -6px;
    right: -2px;   
}

#greetingDialog .open-btn {
    background: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

#greetingDialog .button-container-join {
    right: -0.75em;
    width: 9.9em;
}

#greetingDialog .button-container-create {
    width: 100%;
}

#greetingDialog .orText {
    text-align: center;
    color: rgb(128, 128, 128);
}

#greetingDialog .orBlock {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    grid-template-rows: 1fr;
    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 480px)  { 
    #greetingDialog {
        align-self: center;
        max-width: 80%;
        margin-left: 5%;
        margin-right: 5%;
    }

    #greetingDialog .button-container-join {
        width: 55%;
    }

    #greetingDialog input {
        width: 35%;
        padding: 0 0 0 0.5em;
    }
}