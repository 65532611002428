input[type=file] {
    opacity: 0;
}

label[for=photo_upload] {
    opacity: 1;
    font-size: 13pt;
    width: fit-content;
    border-radius: 5px;
    border: ridge lightgray;
    border-width: 1px;
    margin-bottom: 10em;
    height: fit-content;
    padding: 0.5em;
    cursor: pointer;
    background-color: white;
    color: var(--mySea);
}

label[for=photo_upload] > svg {
    fill: var(--mySea)
}
