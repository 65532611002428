#homePage #greetingDialog {
    right: 2%;
    bottom: 2%;
    position: fixed;
    display: flex;
    z-index: 2;
}

#homePage .pinDialog {
    z-index: 3;
    top: 2%;
    right: 2%;
    position: fixed;
}
