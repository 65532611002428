#projectDetailPage {
    display: flex;
}

#projectDetailPage .Sidebar {
    position: fixed;
    left: 0px;
}

#projectDetailPage .descriptionSection, .areaSection {
    text-align: justify;
    flex: 2;
}

#projectDetailPage .projectCard .areaSection .map-container {
    height: 50vh;
    top: 50%;
    left: 50%;
    background-color: #00363d;
    margin-bottom: 1em;
    margin-top: 1em;
    border-radius: 10px;
}

#projectDetailPage .projectCard .areaSection .map-container>.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    border-radius: 10px;
}

@media screen and (max-width: 480px) {
    #projectDetailPage .projectCard {
        width: 100%;
        margin: 10px;
    }
}

@media screen and (min-width: 481px) {
    #projectDetailPage .projectCard {
        width: 80%;
        margin: 5em 10% 5em 20%;
    }
}

#projectDetailPage .projectCard {

    background-color: white;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
}

#projectDetailPage .projectDetailsRow {
    display: flex;
    flex-direction: row;
    padding: 1em 2em;
}

.alignRowRight {
    flex-direction: row-reverse !important;
    text-align: right;
}

/* On screens that are 992px or less - one column per row */
@media screen and (max-width: 992px) {
    #projectDetailPage .projectDetailsRow {
        flex-direction: column;
    }
}

#projectDetailPage .buttons {
    margin-top: 10em;
}

#projectDetailPage span {
    font-size: 1em;
}

#projectDetailPage p {
    margin: 0.1em 0;
}

#projectDetailPage .headline2 {
    margin-bottom: 0;
    margin-top: 2em;
}