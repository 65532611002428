.resultsDialog {
    display: flex;
    flex-direction: column;
}

.resultsDialog .pinSection {
    display: flex;
}

.resultsDialog .pinTotal {
    align-self: center;
    margin: 0 1.5em 0 0.5em;
}
