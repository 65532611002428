.pinDialog {
    flex-direction: column;
    width: 20em;
    height: fit-content;
    padding: 0.15em 1em 0.75em;
    background-color: #ffffff;
    box-shadow: var(--myBoxShadow);
    border-radius: 10px;
}

.pinDialog .actionMenu {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
}

.pinDialog .actionMenu > div {
    cursor: pointer;
    margin: 0 0.25em;
}

.pinDialog img {
    margin-top: 1em;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .pinDialog {
        margin-top: 25%;
    }
}