button.backButton {
  background-color: var(--mySea);
  color: white;
  font-size: 13pt;
  width: 9em;
  border-radius: 5px;
  border: 0px;
  margin-bottom: 1em;
  align-self: center;
  height: 2.5em;
  cursor: pointer;
}

.backIcon {
  fill: white;
  margin-right: 0.5em;
}