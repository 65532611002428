#addProjectPage>form {
    display: flex;
    margin: 5em 10%;
    box-shadow: var(--myBoxShadow);
    background-color: white;
    border-radius: 10px;
    flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
    #addProjectPage>form {
        width: 100%;
        margin: 10px;
    }
}

@media screen and (min-width: 481px) {
    #addProjectPage>form {
        width: 80%;
        margin: 5em 10% 5em 20%;
    }
}

#addProjectPage .textField {
    border-radius: 10px;
    border-width: 1px;
    margin-bottom: 0.625em;
}

#addProjectPage .textField, #addProjectPage #descriptionInput {
    width: 85%;
}

#addProjectPage #descriptionInput {
    height: 5em;
}

.myCard {
    display: flex;
}

#addProjectPage .bigFlex {
    display: flex;
}

#addProjectPage {
    display: flex;
}

#addProjectPage .headline1 {
    height: auto
}

#addProjectPage .areaSection {
    text-align: justify;
    margin: 1em;
    /* make buttons stick to the right */
}

#addProjectPage .addProjectRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1em 2em;
    width: 100%;
}

.alignRowRight {
    flex-direction: row-reverse !important;
    text-align: right;
}

/* On screens that are 992px or less - one column per row */
@media screen and (max-width: 992px) {
    #addProjectPage .addProjectRow {
        flex-direction: column;
    }
}

#addProjectPage .descriptionSection {
    flex: 1;
}

#addProjectPage .areaSection {
    margin-top: 0.5em;
    flex: 1;
}

#addProjectPage .map-container {
    width: 100%;
    height: 45vh;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
}

#addProjectPage .map-container .leaflet-container {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
}

#addProjectPage .buttons {
    margin-top: 10em;
}

#addProjectPage span {
    font-size: 1em;
}

#addProjectPage p {
    margin: 0.1em 0;
}

#addProjectPage .headline2 {
    margin-bottom: 0;
    margin-top: 1em;
}