#contactPage {
    display: flex;
    flex-direction: column;
    align-self: center;
}

#contactPage a {
    padding-left: 0.5em;
}

#contactPage > .bodyContactDetails {
    display: flex;
    flex-wrap: wrap;
}

div #contactPage .contactDetails, .meetMeInPerson, .aboutMe {
    padding: 0 1em;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#contactPage .mapClientAddress {
    border: 0;
    overflow:hidden;
    padding-bottom:85%;
    position:relative;
    height:0;
}

#contactPage .mapClientAddress iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position: absolute;
    border-radius: 10px;
}

#contactPage div .clientPhoto {
    background: url("../assets/img/clientImg.png");
    width: 17em;
    height: 18em;
    border-radius: 10px;
    background-size: cover;
}

/* Desktops */
@media screen and (min-width: 821px)  { 
    
    #contactPage > .bodyContactDetails {
        margin-left: 20%;
        margin-right: 20%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    div #contactPage .contactDetails, .meetMeInPerson, .aboutMe {
        margin: 2em;
        top: 19%;
    }
    
    #contactPage div .aboutMe {
        height: fit-content;
        width: 51em;
        left: 28%;
    }

    #contactPage div .contactDetails {
        width: 17em;
        height: 31em;
        left: 28%;
        padding-bottom:1em;
    }
    
    #contactPage div .meetMeInPerson {
        width: 28em;
        min-height: fit-content;
        left: 52%;
        padding-bottom: 1em;
    }
}

/* Tablets */
@media screen and (min-width: 481px) and (max-width: 820px) { 

    #contactPage > .bodyContactDetails {
        margin-left: 4em;
        flex-direction: column;
        align-items: center;
    }
    
    div #contactPage .contactDetails, .meetMeInPerson, .aboutMe {
        margin-bottom: 10%;
        width: 17em;
        height: fit-content;
        padding-bottom: 1em;
    }

    #contactPage div .contactDetails {
        margin-top: 25%;
    }
}

/* Phones */
@media screen and (max-width: 480px)  {
    
    #contactPage > .bodyContactDetails {
        flex-direction: column;
        align-items: center;
    }
    
    div #contactPage .contactDetails, .meetMeInPerson, .aboutMe {
        margin-bottom: 10%;
        width: 17em;
        height: fit-content;
        padding-bottom: 1em;
    }

    #contactPage div .contactDetails {
        margin-top: 10%;
    }
   
}